import * as React from 'react';

export default function Dashboard() {


  return (
  <>
        <footer className="footer">
          <div className="footer-content justify-content-between">
            <p className="m-b-0">
            All rights reserved By 7-Continents Travel Copyright © 2024.
            </p>
          
          </div>
        </footer>
       

  </>
  );
}