import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [listold,setListold]=useState([])
  const [d1,setD1]=useState("type");

  useEffect(() => {
    const a=localStorage.getItem("DEV");
    if(a!="true")
    {
      window.location.replace("/admin_main")
    }
  
  
  }, []);




  const all_agent_get = async (d)=> {
    const data = new FormData();
    data.append('v',d);
   
            axios({
              method: 'post',
              url: '/api_lock/admin/dev/mysql.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                   // setAgentList(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
        <div className='card' style={{padding:"20px"}} >

<div className='row'>
{d1}
<div className='col-12'>
<p>Testing</p>
<p>UPDATE inquiry SET phone='443060408704' WHERE phone ='923060408704';</p>
<textarea  rows="1" cols="140" ></textarea>
<h2>Apply</h2>
<textarea value={d1} rows="4" cols="140" onChange={(v)=>{ setD1(v.target.value)}}></textarea>

</div>
</div>
<div className='row'>
<div className='col-3'>
<button onClick={()=>{
if(d1=="")
{
    alert("Sql null")
}else{
    all_agent_get(d1)
}

}}  className="btn btn-primary m-r-5">Apply</button>

</div>
</div>
{"https://portal.rahmantours.co.uk/api_lock/admin/dev/mysql.php?v="+d1}
<div style={{padding:"20px"}} className='row'>
    <div className='col-12'>
        <a target='__blank' href={"https://portal.rahmantours.co.uk/api_lock/admin/dev/mysql.php?v="+d1}>Apply</a>
    </div>
</div>
<div style={{padding:"20px"}} className='row'>
    <div className='col-12'>
        <a target='__blank' href='https://portal.rahmantours.co.uk/api_lock/admin/dev/table_show.php'>Table Show Name and Attribute for update</a>
    </div>

</div>


</div>
</div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}