import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [days,setDays]=useState("");

  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])

  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)

  useEffect(() => {
    const a=localStorage.getItem("Agent");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
    
        console.log(JSON.parse(a)[0].team,"team id ")
        getInq(JSON.parse(a)[0].id,"open");


    }


  
  
  }, []);

  const getInq = async (uid,status)=> {
    const data = new FormData();

    data.append('uid',uid);
    data.append('status',status);

            axios({
              method: 'post',
              url: '/api_lock/complaint/complaint_list_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                setList(s.data)
                

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



            const getfilter_Agent = status => {
            const a=list;
            if(listoldStatus)
            {
              const newArray = a.filter(item => item.agent_name === status);
              setList(newArray)
              console.log("array s ",newArray)
            }else{
              setListold(a)
              setListoldStatus(true)
              const newArray = a.filter(item => item.agent_name === status);
              setList(newArray)
              console.log("array ",newArray)
        
            }
        
          
            
          }
          const TeamList = async (typ,id)=> {
            const data = new FormData();
                data.append('team_id',id);
                data.append('type',typ);
                    axios({
                      method: 'post',
                      url: '/api_lock/manager/team_agent.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                         if(typ=="Agent"){
        
                            setAgentList(s.data)
                        }
            
                   console.log(s.data)
            
            
            
                         
                 
                      })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };
  return (
  <>

  <Nav />
      <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <div className='row'>
    <div className='col-6'>
       <h4> Complaint  Total : {list.length} </h4>
    </div>
   </div>



   
   </div>

   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
              <tr role="row">
                  <th className="sorting_asc">
                    ID
                  </th>
                  <th className="sorting_asc">
                    Type
                  </th>
                  
                  <th className="sorting">
                    Agent ID
                  </th>
                  <th className="sorting">
                    Agent Name
                  </th>
                  <th  className="sorting">
                   Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v,i)=>(   
        

              <>
                <tr role="row" className="odd">
              
                  <td>{v.id} </td>
                  <td>{v.type}</td>
                  <td>{v.agent_id} </td>
                  <td>{v.agent_name} </td>
                  <td>  <a href={'/complaint_show_agent?id='+v.id}>
              <button  className="btn btn-icon btn-warning">
  <i className="anticon anticon-select"></i> 
</button></a></td>
   
   
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}