import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav";
import Footer from "./footer";
import axios from 'axios';
import { useState, useEffect } from "react";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
 
  const getCount = async ()=> {
    
    const data = new FormData();
        
            axios({
              method: 'post',
              url: '/api_lock/admin/trash_counter_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                console.log("count",s.data)
                const sortedDates = sortDates(s.data);
                setCounterList(sortedDates)
                setDataarray(sortedDates)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

const sortDates = (dateObjects) => {
    const today = new Date();
    const sortedDates = dateObjects.sort((a, b) => {
      const dateA = new Date(a.v4);
      const dateB = new Date(b.v4);

      // Calculate the difference in days from today
      const diffA = Math.abs(today - dateA) / (1000 * 3600 * 24);
      const diffB = Math.abs(today - dateB) / (1000 * 3600 * 24);

      // If both are today's date, maintain the original order
      if (diffA === 0 && diffB === 0) {
        return 0;
      }

      // Sort by ascending order of difference
      return diffA - diffB;
    });

    return sortedDates;
  };




  const getInq = async ()=> {
    
    const data = new FormData();
        
            axios({
              method: 'post',
              url: '/api_lock/inq/trash.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const SetTrash = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    data.append('s',"");
        
            axios({
              method: 'post',
              url: '/api_lock/inq/move.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList([])
                getInq();
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const Delete_server = async ()=> {
    const data = new FormData();
    
        
            axios({
              method: 'post',
              url: '/api_lock/admin/trash_remove.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                getInq();
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
  const delete_db= async (id)=> {
    const data = new FormData();
    data.append('id',id);
    axios({
              method: 'post',
              url: '/api_lock/admin/delete_db_counter.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                getInq();
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const [list,setList]=useState([])
  const [counterList,setCounterList]=useState([])


const goToNextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

const goToPreviousPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};

  useEffect(() => {
    getInq();
  }, []);

  const [dataArray,setDataarray]=useState([])
  const [currentPage, setCurrentPage] = useState(1);
  
// Assuming your array is named 'dataArray' and 'currentPage' is your current page number
const itemsPerPage = 400;
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const currentItems = dataArray.slice(startIndex, endIndex);

const totalPages = Math.ceil(dataArray.length / itemsPerPage);



  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
<div className="card">
   <div style={{paddingTop:"20px",paddingLeft:"20px"}}>
   <h4> 
<button onClick={()=>{ 
if(counterList.length<1)
{
  getCount()

}else{
  setCounterList([])
  
}
 }} className="btn btn-icon btn-warning"><i className="anticon anticon-menu-unfold"></i> </button>
  Total {list.length} Trash Box <span onClick={getInq}><i style={{color:"#FFC107"}} className="anticon anticon-undo"></i> </span><span onClick={()=>{
     if (window.confirm("Delete record permanently?")) {

          setList([])
      Delete_server()
  }
   }}><i style={{color:"red"}} className="anticon anticon-delete"></i></span> </h4>
   


{
  counterList.length>0?<>
  <h3>Total: 
    {counterList.length}
  </h3>
    <div id="data-table_wrapper" className="dataTables_wrapper dt-bootstrap4">
      
      <div className="row">
        <div className="col-sm-12">
          <table
            id="data-table"
            className="table dataTable"
            role="grid"
            aria-describedby="data-table_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc">
                  ID
                </th>
                <th
                  className="sorting_asc">
                  value
                </th>
                <th
                  className="sorting_asc">
                  Count
                </th>
                <th
                  className="sorting">
                  Type
                </th>
                <th
                  className="sorting">
                  Last Upadted
                </th>
                <th
                  className="sorting">
                  Delete
                </th>
                
               
              </tr>
            </thead>
            <tbody>
    {
      currentItems.map((v)=>(   
      

            <>
              <tr role="row" className="odd">
                <td>{v.id} </td>
                <td>{v.v1} </td>
                <td>{v.v2}</td>
                <td>{v.v3}</td>
                <td>{v.v4}</td>
                <td><button onClick={()=>{ delete_db(v.id)}} type="button" class="btn btn-warning btn-lg btn-block"><span ><i style={{color:"black"}} className="anticon anticon-delete"></i></span></button></td>
                </tr>
         
             
            </>
         

    ))
    }
      </tbody>
      </table>
        </div>
      </div>
      <button onClick={goToPreviousPage} disabled={currentPage === 1}>
  Previous
</button>
<button onClick={goToNextPage} disabled={currentPage === totalPages}>
  Next
</button>
    </div></>:<></>
}

 


   </div>
   <div className="card-body">
 <div className="m-t-25">
      <div id="data-table_wrapper" className="dataTables_wrapper dt-bootstrap4">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Name
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Email
                  </th>
                  <th
                    className="sorting">
                    Phone
                  </th>
                  <th
                    className="sorting">
                    Ad/ch/In
                  </th>
                  <th
                    className="sorting"
                
                  >
                    Ip/Ph/Em
                  </th>
             
                  <th
                    className="sorting"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                  <td>{v.phone}</td>
                  <td>{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>

      
           
           <div className='row'>
            <div className='col-3'>IP</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.ip}</b></div>
           </div>

           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>


           <div className='row'>
            
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
        <Button>{v.sip}/{v.sp}/{v.se}</Button>
      </HtmlTooltip>
      </td>
   
                 
                  

                  

                  <td>

                  <button onClick={()=>{
                    SetTrash(v.id)
                  }} className="btn btn-icon btn-warning">
    <i className="anticon anticon-rollback"></i> 
</button> 
                
                  </td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}