import React, { useEffect } from 'react';
import axios from 'axios';

function BackgroundTask() {

  const User_by = async (id)=> {
    const data = new FormData();
    data.append('uid',id );
        axios({
          method: 'post',
          url: '/api_lock/user/uid_block.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
          if(s.data[0].msg=="false")
          {
                  localStorage.clear()
                   window.location.replace("/login")
          }else{
            const k=s.data[0].ac;
            localStorage.setItem("Access",k)
          }
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };
  

  useEffect(() => {
    const interval = setInterval(() => {
      // Your background task code goes here
      console.log('Background');
      const a=localStorage.getItem("Admin");
        
      if(a==null)
      {
        
      }else{
        const z=JSON.parse(a)
        console.log("Background_task_run",z[0].team)

        if(z[0].id==1)
          {
            
            localStorage.setItem("Access",JSON.stringify([{"a1":true,"a2":true,"a3":true,"a4":true,"a5":true,"a6":true,"a7":true,"a8":true,"a9":true,"a10":true,"a11":true,"a12":true,"a13":true,"a14":true,"a15":true,"a16":true,"a17":true,"a18":true,"a19":true,"a20":true,"a21":true,"a22":true,"a23":true,"a24":true,"a25":true,"a26":true,"a27":true,"a28":true,"a29":true,"a30":true,"a31":true,"a32":true,"a33":true}]))
         
            
          }else{
          User_by(z[0].id);
         
          }
  
       
      }



    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* Any JSX you want to render */}
    </div>
  );
}

export default BackgroundTask;
