import * as React from 'react';
import CryptoJS from 'crypto-js';
import {useLocation} from "react-router-dom"
import { useState, useEffect } from "react";
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";
import { Email } from '@mui/icons-material';


export default function Dashboard() {

  const [teamList,setTeamList]=useState([])
  const [name,setName]=useState("")
  const [ex,setEx]=useState("")
  const [username,setUserame]=useState("")
  const [pin,setPin]=useState("")
  const [pass,setPassword]=useState("")
  const [type,setType]=useState("")
  const [team,setTeam]=useState("")
  const [msg,setMsg]=useState("")
  const [access,setAccess]=useState([{a1:false,a2:false,a3:false,a4:false,a5:false,a6:false,a7:false,a8:false,a9:false,a10:false,a11:false,a12:false,a13:false,a14:false,a15:false,a16:false,a17:false,a18:false,}])


  useEffect(() => {

    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a24!=true){
      window.location.replace("/admin_main")
    }


    TeamList()
    get()
  }, []);

  const updateObject = ( keyToUpdate, newValue) => {
    setAccess(prevData => {
      const newData = [...prevData]; // Create a copy of the array
      if(keyToUpdate==='a9')
      {
        newData[0] = { ...newData[0],'a9': newValue,'a8':newValue };
        
        
      }else{
        newData[0] = { ...newData[0], [keyToUpdate]: newValue };
        
      }
      
      return newData; // Update the state with the new array
    });
  };



  const get_user_byid = async (id)=> {
const data = new FormData();
    data.append('id',id);
        axios({
          method: 'post',
          url: '/api_lock/user/get_user_byid.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log(s.data)
            setName(s.data[0].name)
            setEx(s.data[0].ex)
            setPassword( decryptText(s.data[0].pass))
            setPin(s.data[0].pin)
            setTeam(s.data[0].team)
            setType(s.data[0].type)
            setUserame(s.data[0].username)
            console.log("====>",s.data[0].acc)
            if(s.data[0].type=="Admin")
            {
              setAccess(JSON.parse(s.data[0].acc))
            }
            


        })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const TeamList = async ()=> {
const data = new FormData();
    data.append('status',"login");
        axios({
          method: 'post',
          url: '/api_lock/team/active_list.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            setTeamList(s.data)
        })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

    const get =()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    get_user_byid(id)
    };
    
    const UserNew = async ()=> {
    setMsg("")
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    const data = new FormData();
    data.append('id',id);
    data.append('pass',encryptText(pass));
    data.append('name',name);
    data.append('ex',ex);
    data.append('pin',pin);
    data.append('type',type);
    data.append('team_id',team);
    data.append('acc',JSON.stringify(access));
        axios({
          method: 'post',
          url: '/api_lock/user/edit.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            if(s.data[0].msg=="pass")
            {
                

                window.location.replace("/users")
                

            }else{
                window.location.replace("/login")
            }   

            console.log('response =>>', s);




            
  
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      
      const encryptText = (value) => {
        const encrypted = CryptoJS.AES.encrypt(value,"7ct").toString();
        return encrypted
      };
    
      const decryptText = (value) => {
        const decrypted = CryptoJS.AES.decrypt(value,"7ct").toString(CryptoJS.enc.Utf8);
        return decrypted;
      };


  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
<div className='card' style={{padding:"20px"}} >
    <h2>Edit User</h2>
<div className='row'>
 
    <div className='col-4'>
    <div class="form-group">
        <label for="formGroupExampleInput">Email </label>
        <input type="text"  value={username} disabled class="form-control" id="formGroupExampleInput" placeholder="Username"/>
    </div>
    </div>
    <div className='col-4'>
    <div class="form-group">
        <label for="formGroupExampleInput">Password  </label>
        <input type="text"  value={pass} onChange={(v)=>{ setPassword(  v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Password"/>
    </div>
    </div>
    <div className='col-4'>
    <div class="form-group">
        <label for="formGroupExampleInput">Pin </label>
        <input type="text"  value={pin} maxLength={4} onChange={(v)=>{ setPin(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Pin"/>
    </div>
    </div>

</div>
<div className='row'>
    <div className='col-3'>
    <div class="form-group">
        <label for="formGroupExampleInput">Name</label>
        <input type="text"  value={name} onChange={(v)=>{ setName(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Name"/>
    </div>
    </div>
    <div className='col-2'>
    <div class="form-group">
        <label for="formGroupExampleInput">Extension</label>
        <input type="text"  value={ex} onChange={(v)=>{ setEx(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Extension"/>
    </div>
    </div>
    <div className='col-2'>
    <div class="form-group">
        <label for="formGroupExampleInput">Type</label>
        <select  value={type} onChange={(v)=>{ setType(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>
  <option>Agent</option>
  <option>Manager</option>
  <option>Admin</option>
  {/* <option>Admin</option> */}

</select>
    </div>
    </div>
    <div className='col-2'>
    <div class="form-group">
        <label for="formGroupExampleInput">Team</label>
        <select  value={team} onChange={(v)=>{ setTeam(v.target.value)}} className="form-select" aria-label="Default select example">
        <option value={""}>Select</option>
        {
                                teamList.map(v=>(<option value={v.id}>{v.name}</option> ))}
            
            
        </select>
    </div>
    </div>

</div>
{
    msg==""?<></>:<div className="alert alert-danger">
    {msg}
</div>
}
<div className='row'>
    <div className='col-6'>
    {
      type!="Admin"?<>
  <button onClick={()=>{

if(username=="")
{
    setMsg("Username Insert")
}else if(pass=="" )
{
  setMsg("Password Insert")
}else if(pin=="")
{
    setMsg("Pin Insert")
}else if(name=="" )
{
    setMsg("Name Insert")
}else if(type=="")
{
    setMsg("Select Type")
}else{

  if(type=="Admin")
  {
    UserNew()
  }else{

    if(team=="")
    {
        setMsg("Select Team")
    }else{
      UserNew()
    }
  }   
}
}} className="btn btn-primary m-r-5">Apply</button>
    </>:<></>    }
    </div>

</div>



</div>

{
  type=="Admin"?<>
    <div className='card' style={{padding:"20px"}}>
  <>
  <h4>Access as Admin</h4>
  
    <div className='row'>
      

      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a1" checked={access[0].a1} onChange={()=>{ updateObject('a1',!(access[0].a1)) }} />
        <label className="form-check-label" htmlFor="a1">Dashboard</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a2" checked={access[0].a2} onChange={()=>{ updateObject('a2',!(access[0].a2)) }} />
        <label className="form-check-label" htmlFor="a2">Inquiry Sent Pending</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a3" checked={access[0].a3} onChange={()=>{ updateObject('a3',!(access[0].a3)) }} />
        <label className="form-check-label" htmlFor="a3">Inquiry View Pending</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a4" checked={access[0].a4} onChange={()=>{ updateObject('a4',!(access[0].a4)) }} />
        <label className="form-check-label" htmlFor="a4">Response Waiting</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a5" checked={access[0].a5} onChange={()=>{ updateObject('a5',!(access[0].a5)) }} />
        <label className="form-check-label" htmlFor="a5">Follow Up List</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a6" checked={access[0].a6} onChange={()=>{ updateObject('a6',!(access[0].a6)) }} />
        <label className="form-check-label" htmlFor="a6">Reminder List</label></div>
      </div>
    </div>
    
    <div className='row'>
      

      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a7" checked={access[0].a7} onChange={()=>{ updateObject('a7',!(access[0].a7)) }} />
        <label className="form-check-label" htmlFor="a7">Voicemail List</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a8" checked={access[0].a8} onChange={()=>{ updateObject('a8',!(access[0].a8)) }} />
        <label className="form-check-label" htmlFor="a8">Report Catlog Section A</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a9" checked={access[0].a9} onChange={()=>{ updateObject('a9',!(access[0].a9)) }} />
        <label className="form-check-label" htmlFor="a9">Report Catlog Section A & B</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a10" checked={access[0].a10} onChange={()=>{ updateObject('a10',!(access[0].a10)) }} />
        <label className="form-check-label" htmlFor="a10">Duplicate Inquirers</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a11" checked={access[0].a11} onChange={()=>{ updateObject('a11',!(access[0].a11)) }} />
        <label className="form-check-label" htmlFor="a11">All Users</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a12" checked={access[0].a12} onChange={()=>{ updateObject('a12',!(access[0].a12)) }} />
        <label className="form-check-label" htmlFor="a12">New User</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a13" checked={access[0].a13} onChange={()=>{ updateObject('a13',!(access[0].a13)) }} />
        <label className="form-check-label" htmlFor="a13">Team View & Add</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a14" checked={access[0].a14} onChange={()=>{ updateObject('a14',!(access[0].a14)) }} />
        <label className="form-check-label" htmlFor="a14">Statis & Portal Log & PIN Log</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a15" checked={access[0].a15} onChange={()=>{ updateObject('a15',!(access[0].a15)) }} />
        <label className="form-check-label" htmlFor="a15">Export Report</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a16"checked={access[0].a16} onChange={()=>{ updateObject('a16',!(access[0].a16)) }} />
        <label className="form-check-label" htmlFor="a16">Import Inquiries</label></div>
      </div>

            
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a17"checked={access[0].a17} onChange={()=>{ updateObject('a17',!(access[0].a17)) }} />
        <label className="form-check-label" htmlFor="a17">Client Subscriptions</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a18"checked={access[0].a18} onChange={()=>{ updateObject('a18',!(access[0].a18)) }} />
        <label className="form-check-label" htmlFor="a18">Contact US & Complaint </label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a19"checked={access[0].a19} onChange={()=>{ updateObject('a19',!(access[0].a19)) }} />
        <label className="form-check-label" htmlFor="a19">Note Book View</label></div>
      </div>
     
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a20"checked={access[0].a20} onChange={()=>{ updateObject('a20',!(access[0].a20)) }} />
        <label className="form-check-label" htmlFor="a20">Call Recording Review</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a21"checked={access[0].a21} onChange={()=>{ updateObject('a21',!(access[0].a21)) }} />
        <label className="form-check-label" htmlFor="a21">Call Recording listening </label></div>
      </div>

      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a22"checked={access[0].a22} onChange={()=>{ updateObject('a22',!(access[0].a22)) }} />
        <label className="form-check-label" htmlFor="a22">Quotations View</label></div>
      </div>


      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a23"checked={access[0].a23} onChange={()=>{ updateObject('a23',!(access[0].a23)) }} />
        <label className="form-check-label" htmlFor="a23">Booking Form View</label></div>
      </div>

      
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a24"checked={access[0].a24} onChange={()=>{ updateObject('a24',!(access[0].a24)) }} />
        <label className="form-check-label" htmlFor="a24">Access Editor</label></div>
      </div>

      {/* <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a25"checked={access[0].a25} onChange={()=>{ updateObject('a25',!(access[0].a25)) }} />
        <label className="form-check-label" htmlFor="a25">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a26"checked={access[0].a26} onChange={()=>{ updateObject('a26',!(access[0].a26)) }} />
        <label className="form-check-label" htmlFor="a26">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a27"checked={access[0].a27} onChange={()=>{ updateObject('a27',!(access[0].a27)) }} />
        <label className="form-check-label" htmlFor="a27">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a28"checked={access[0].a28} onChange={()=>{ updateObject('a28',!(access[0].a28)) }} />
        <label className="form-check-label" htmlFor="a28">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a29"checked={access[0].a29} onChange={()=>{ updateObject('a29',!(access[0].a29)) }} />
        <label className="form-check-label" htmlFor="a29">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a30"checked={access[0].a30} onChange={()=>{ updateObject('a30',!(access[0].a30)) }} />
        <label className="form-check-label" htmlFor="a30">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a31"checked={access[0].a31} onChange={()=>{ updateObject('a31',!(access[0].a31)) }} />
        <label className="form-check-label" htmlFor="a31">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a32"checked={access[0].a32} onChange={()=>{ updateObject('a32',!(access[0].a32)) }} />
        <label className="form-check-label" htmlFor="a32">Import Inquiries</label></div>
      </div>
      <div className='col-4'>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" defaultValue={false} id="a33"checked={access[0].a33} onChange={()=>{ updateObject('a33',!(access[0].a33)) }} />
        <label className="form-check-label" htmlFor="a33">Import Inquiries</label></div>
      </div> */}





    </div>
    
  <div className='row' style={{padding:"20px"}}>
    <div className='col-6'>
    <button onClick={()=>{

          if(username=="")
          {
              setMsg("Username Insert")
          }else if(pass=="" )
          {
            setMsg("Password Insert")
          }else if(pin=="")
          {
              setMsg("Pin Insert")
          }else if(name=="" )
          {
              setMsg("Name Insert")
          }else if(type=="")
          {
              setMsg("Select Type")
          }else{

            if(type=="Admin")
            {
              UserNew()
            }else{

              if(team=="")
              {
                  setMsg("Select Team")
              }else{
                UserNew()
              }
            }   
          }
    }} className="btn btn-primary m-r-5">Apply</button>
    </div>

</div>


    
    
    </>

  </div>
  </>:<></>
}
</div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}