import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (id,team_id)=> {
    const data = new FormData();
    data.append('team_id',team_id);
    data.append('g_id',id);
    data.append('d1',d1);
    data.append('d2',d2);
            axios({
              method: 'post',
              url: '/api_lock/agent/report.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)

                console.log(s.data)
               
              
                
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };




  const [list,setList]=useState([])
  const [state,setstate]=useState([])
  const [selected,setSelected]=useState(null)
  const [user,setUser]=useState(null);
  const [d1,setD1]=useState(null);
  const [d2,setD2]=useState(null);


  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;
  }
  const getTypeCount = status => {
    return list.filter(item => item.sender_type === status).length;
  }

  useEffect(() => {
    const a=localStorage.getItem("Agent");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      console.log(a) 
    }


  
  
  }, []);

  function getRemainingDays(targetDate) {
    const currentDate = new Date();
    const remainingTime = new Date(targetDate) - currentDate;
    
    // Convert remaining time from milliseconds to days
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
    
    return remainingDays;
}
  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">

        <div className='card' style={{padding:"20px"}} >
<h2>Inquirers</h2>
<div className='row'>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date Start</label>
<input type="date"  value={d1} onChange={(v)=>{ setD1(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date End</label>
<input type="date"  value={d2} onChange={(v)=>{ setD2(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='row'>
<div className='col-3'>
<button onClick={()=>{

if(d1===null)
{
alert("Select Date")
}else if(d2===null)
{
alert("End Date")
}else{

  if(user=="")
  {

  }else{
    setList([])
    //setSelected_website()
    setSelected()
    const s=getRemainingDays(d1)
    if(s<-61)
    {
      alert("Reports for only the past 2 months are allowed.")
      setD1()
    }else{

      getInq(JSON.parse(user)[0].id,JSON.parse(user)[0].team)
    }

    
    
  }

}
}}  className="btn btn-primary m-r-5">Get</button>

</div>

</div>

</div>

</div>

            <h2 style={{color:"#054089"}}>Total Inquires : {list.length}</h2>
          <div className="row">
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Reminder</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Reminder")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookout</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookout")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Bookin </p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Bookin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Booked</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Booked")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Busy</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Busy")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Follow-up</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Follow-up")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Responsed</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Responsed")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Not-Interested</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Not-Interested")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Invalid</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Invalid")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Voicemail</p>
                      <h2 className="m-b-0">
                      <span>{getStatusCount("Voicemail")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Admin</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Admin")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Manager</p>
                      <h2 className="m-b-0">
                      <span>{getTypeCount("Manager")}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        
     
          
          
          
          </div>
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Report </h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                 
                  <th className="sorting">Account</th>
                  <th
                    className="sorting">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time}</td>
                  <td>{v.sender_time}</td>
                  <td>{v.agent_status}</td>
        
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <>{v.url}</>
      
        }
      >
        <Button>{v.sender_type}</Button>
      </HtmlTooltip>
      </td>
      
     <td>
      <a target='black_' href={"/view_inquiry?id="+v.id}>
        <button 
        className="btn btn-warning m-r-5">View</button>
                 
                 </a>
                  </td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}