import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])

  const [selected,setSelected]=useState(null)
  const [selected_t,setSelected_t]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [teamList,setTeamList]=useState([])

  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)
  

  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      
        
        all_follow_up()
        all_agent()
        TeamList()

        const access=JSON.parse(localStorage.getItem("Access"))
        if(access[0].a7!=true){
         window.location.replace("/admin_main")
        }

      
    }

  
  }, []);

  const TeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api_lock/team/all_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeamList(s.data)
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const all_agent = async ()=> {
    const data = new FormData();

            axios({
              method: 'post',
              url: '/api_lock/admin/all_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                 setAgentList(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const all_follow_up = async (id)=> {
    const data = new FormData();
    
  
            axios({
              method: 'post',
              url: '/api_lock/admin/voicemail.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const getfilter_Agent = status => {
            const a=list;
            if(listoldStatus)
            {
              const newArray = a.filter(item => item.agent_name === status);
              setList(newArray)
              console.log("array s ",newArray)
            }else{
              setListold(a)
              setListoldStatus(true)
              const newArray = a.filter(item => item.agent_name === status);
              setList(newArray)
              console.log("array ",newArray)
        
            }
        
          
            
          }

          const getfilter_Team = status => {
            const a=list;
            if(listoldStatus)
            {
              const newArray = a.filter(item => item.team_id === status);
              setList(newArray)
              console.log("array s ",newArray)
            }else{
              setListold(a)
              setListoldStatus(true)
              const newArray = a.filter(item => item.team_id === status);
              setList(newArray)
              console.log("array ",newArray)
        
            }
        
          
            
          }

  return (
  <>

  <Nav />
      <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <div className='row'>
    <div className='col-3'>
       <h4>Voicemail List <br/> Total : {list.length} </h4>
    </div>
   </div>
   <div className='row' >

<div className='col-3' >
<Autocomplete
  disablePortal
  id="size-small-standard"
    size="small"
   value={selected}
    onChange={(event, newValue) => {
        console.log(newValue)
        setSelected(newValue)  
        if(newValue===null)
        {
          
        }else{
          getfilter_Agent(newValue.name)
          

        }

    }}
  options={agentList}
  // sx={{ width: 100 }}
  getOptionLabel={(option) => option.name}
  renderOption={(props, option) => (
    <Box component="li"  {...props}>
      
      {option.name} 
    </Box>
  )}
  renderInput={(params) => <TextField {...params}  label="Select Agent Name" />}
/>
</div>

<div className='col-3' >
<Autocomplete
  disablePortal
  id="size-small-standard"
    size="small"
   value={selected_t}
    onChange={(event, newValue) => {
        console.log(newValue)
        setSelected_t(newValue)  
        if(newValue===null)
        {
          
        }else{
          getfilter_Team(newValue.id)
          

        }

    }}
  options={teamList}
  // sx={{ width: 100 }}
  getOptionLabel={(option) => option.name}
  renderOption={(props, option) => (
    <Box component="li"  {...props}>
      
      {option.name}
    </Box>
  )}
  renderInput={(params) => <TextField {...params}  label="Select Team" />}
/>
</div>



<div className='col-3' >
{
listoldStatus?        <button style={{margin:"10px"}} onClick={()=>{
const a=listold;
setList(a)
setListoldStatus(false)
setSelected()
setSelected_t()


}}  class="btn btn-success m-r-5">Reset</button>:""
}
</div>
</div>
   </div>

   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
              <tr role="row">
                  <th className="sorting_asc">
                    ID
                  </th>
                  <th className="sorting_asc">
                    Website
                  </th>
                  <th className="sorting_asc">
                Date 
                  </th>
                  
                  
                  <th className="sorting">
                    Agent Name
                  </th>
                  <th  className="sorting">
                   Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
              
                  <td>{v.inq} </td>
                  <td>{v.web}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>
           <div className='row'>
            <div className='col-12'>Email</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.email}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Phone</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.phone}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Adult / Child /Infant </div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.adult} / {v.child} / {v.infant}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>


           <div className='row'>
            
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
       
</center>
          </div>
        }
      >
        <Button>{v.date_portal} {v.date_time}</Button>
      </HtmlTooltip>
      </td>
                 
                  
                  <td>{v.agent_name} </td>
                  <td><a href={"view_inquiry_admin?id="+v.inq}> <button  className="btn btn-warning m-r-5">Info</button></a>
      </td>
   
   
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}