import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "./footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (typek)=> {
    const data = new FormData();
    data.append('type',typek);
        
            axios({
              method: 'post',
              url: '/api_lock/inq/list_type.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setListQA(s.data)
                console.log("Type_Data",s.data)
            
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


          
         
  

  const SetTrash = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    data.append('s',"trash");
        
            axios({
              method: 'post',
              url: '/api_lock/inq/move.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                getInq(type_S)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const send_team = async (id,team_id,admin_id)=> {
    const data = new FormData();
    data.append('inquiry_id',id);
    data.append('team_id',team_id);
    data.append('admin_id',admin_id);
        
            axios({
              method: 'post',
              url: '/api_lock/send_inq/send_team.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                getInq(type_S)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const send_team_to_agent = async (inquiry_id,team_id,admin_id,agent_id)=> {

    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('team_id',team_id);
    data.append('admin_id',admin_id);
    data.append('agent_id',agent_id);
        
            axios({
              method: 'post',
              url: '/api_lock/send_inq/send_team_with_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                console.log(s.data)
                getInq(type_S)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
          
  const [list,setListQA]=useState([])

  
  const [team,setTeam]=useState([])
  const [selected,  ]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [user,setUser]=useState(null);
  const [userAccess,setUserAccess]=useState([{a1:false,a2:false,a3:false,a4:false,a5:false,a6:false,a7:false,a8:false,a9:false,a10:false,a11:false,a12:false,a13:false,a14:false,a15:false,a16:false,a17:false,a18:false,}]);
  const [type,setType]=useState([])
  const [type_S,setType_s]=useState("")

  const Get_type_list = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api_lock/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setType(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
        Get_type_list();
      setUser(a)
      
      TeamList("Agent")
      AllTeamList();
      
      const z=JSON.parse(a)
      if(z[0].id==1)
        {
          
          localStorage.setItem("Access",JSON.stringify([{"a1":true,"a2":true,"a3":true,"a4":true,"a5":true,"a6":true,"a7":true,"a8":true,"a9":true,"a10":true,"a11":true,"a12":true,"a13":true,"a14":true,"a15":true,"a16":true,"a17":true,"a18":true,"a19":true,"a20":true,"a21":true,"a22":true,"a23":true,"a24":true,"a25":true,"a26":true,"a27":true,"a28":true,"a29":true,"a30":true,"a31":true,"a32":true,"a33":true}]))
          setUserAccess([{"a1":true,"a2":true,"a3":true,"a4":true,"a5":true,"a6":true,"a7":true,"a8":true,"a9":true,"a10":true,"a11":true,"a12":true,"a13":true,"a14":true,"a15":true,"a16":true,"a17":true,"a18":true,"a19":true,"a20":true,"a21":true,"a22":true,"a23":true,"a24":true,"a25":true,"a26":true,"a27":true,"a28":true,"a29":true,"a30":true,"a31":true,"a32":true,"a33":true}])
          
        }else{
        User_by(z[0].id);
       
        }

    }
  
  }, []);





  const User_by = async (id)=> {
    const data = new FormData(); 
    data.append('uid',id );
        axios({
          method: 'post',
          url: '/api_lock/user/uid_block.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
  
          if(s.data[0].msg=="false")
          {
                localStorage.clear()
                window.location.replace("/login")
          }else{
            const k=s.data[0].ac;
            localStorage.setItem("Access",k) 
            setUserAccess(JSON.parse(k))
          }

          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      }




  const AllTeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api_lock/team/active_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeam(s.data)
             
    
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  const TeamList = async (typ)=> {
    const data = new FormData();
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api_lock/user/user_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
                 if(typ=="Agent"){

                    setAgentList(s.data)
                }
    
           console.log(s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  return (
  <>
  
<Nav />
  
    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
            <h3>Welcome All Types {type_S} </h3>
            <br/>
           <div className='row'>
            <div className='col-4'>
            <select value={type_S} onChange={(v)=>{ 
              
                setType_s(v.target.value);
                
            }
        } className="form-select" aria-label="Default select example">
            <option>Select Type </option>
                {type.map((v,i)=>(<option>{v.name}</option>))}
                </select>
            </div>
            <div className='col-4'>
           <button onClick={()=>{
             setListQA([]);           
             getInq(type_S);           
           }} className='btn btn-primary' >
            Get
           </button>
            </div>
         
           </div>
           <br/>
{
  userAccess[0].a1&&<>
  <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Latest Entries  <span><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i></span><span onClick={()=>{   window.location.replace("/alltrash");}}><i style={{color:"#0D6EFD"}}  class="anticon anticon-delete"></i></span> </h4>
 </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">

              <thead>
                <tr role="row">
                  
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  {/* <th
                    className="sorting"
          
                  >
                    Name
                  </th> */}
                  <th
                    className="sorting"
                  >
                    Email
                  </th>
                  <th
                    className="sorting">
                    Phone
                  </th><th
                    className="sorting"
                  >
                    Teams
                  </th>
                  <th
                    className="sorting"
                  >
                    Agent
                  </th>
                  <th
                    className="sorting"
                  >
                    Action
                  </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id}  </td>
                  <td><a target='blank__' href={"/dup?value="+v.ip+"&type=ip"}> <Button variant="contained" color={v.sip=="new"?"success":"error"}>{v.sip}</Button></a> {v.web} </td>
                  {/* <td>{v.name}</td> */}
                  <td><a target='blank__' href={"/dup?value="+v.email+"&type=email"}><Button variant="contained" color={v.se=="new"?"success":"error"}>{v.se}</Button></a> {v.email}</td>
                
                  
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>


           
           <div className='row'>
            <div className='col-3'>IP</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.ip}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Ad/ch/In</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} >{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</div>
           </div>
           <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
           </div>

           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>
            <div className='row'>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}}><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
</div>
        }
      ><a target='blank__'  href={"/dup?value="+v.phone+"&type=phone"}><Button variant="contained" color={v.sp=="new"?"success":"error"}>{v.sp}</Button></a> {v.phone}  </HtmlTooltip>
       </td>
   
                 
                  <td>
                    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
      value={selected}
        onChange={(event, newValue) => {
          
         
          if(newValue === null)
          {
            console.log(newValue)
          }else{
            console.log(v.id," = ",newValue.id," = ",JSON.parse(user)[0].id);
            send_team(v.id,newValue.id,JSON.parse(user)[0].id)
          }

        }}

      options={team}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}

      renderInput={(params) => <TextField {...params}  label="Name" />}
    />
                  
                  </td>

                  <td>  
                 
                  <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"

       value={selected}
        onChange={(event, newValue) => {
          
         
          if(newValue === null)
          {
            console.log(newValue)
          }else{
            console.log(newValue)
            console.log(v.id," = ",newValue.id,"  => ",newValue.team," = ",JSON.parse(user)[0].id);
            // (inquiry_id,team_id,admin_id,agent_id)
            send_team_to_agent(v.id,newValue.team,JSON.parse(user)[0].id,newValue.id)
          }

        }}

      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}

      renderInput={(params) => <TextField {...params}  label="Agent" />}
    />
                  
                  </td>

                  <td>

                  <button onClick={()=>{
                    SetTrash(v.id)
                  }} className="btn btn-icon btn-warning">
    <i className="anticon anticon-delete"></i>
</button> 
                
                 
                  </td>
                  <td> <a href={"view_inquiry_admin?id="+v.id}> <button  className="btn btn-warning m-r-5">Info</button></a></td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>

       

          </div>
        </div>
        
      </div>
    </div>
  
  </div>
    
      
    
    </div>
    {/* <div>
          <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvWSAfgDgsENCS0x_qws1Xr9WMwRG_oqZL0QNYjqg0Afo8TLkDIECHSHbq9TSoGy3zXwCMhYlpqZh4/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
          
          </div> */}

          
    </>
}
    





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}