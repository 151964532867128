import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";
import { json } from 'react-router-dom';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)
  const [user,setUser]=useState()
  const [days,setDays]=useState("");
  const [d1,setD1]=useState(null);
  const [d2,setD2]=useState(null);

  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [teamList,setTeamList]=useState([])
  const [selected_t,setSelected_t]=useState(null)
  const [selected_type,setSelected_type]=useState(null)

  const [section_b,setSection_b]=useState(false)
  

  const [selected_website,setSelected_website]=useState(null)
  const [WebsiteList,setWebsiteList]=useState([])
  const [TypeList,setTypeList]=useState([])
  const [f1,setf1]=useState("")
  const [f2,setf2]=useState("")
  const [f3,setf3]=useState("")
  const [f4,setf4]=useState("")


  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      
      setUser(a)
      
      all_agent_get()
      TeamList()
      webList()
      Get_type_list()

      const access=JSON.parse(localStorage.getItem("Access"))
      if(access[0].a8!=true){
       window.location.replace("/admin_main")
      }else{
        setSection_b(access[0].a9)
      }

      
    }
  
  
  }, []);



  const formatDate = (dateString) => {
    // Create a new Date object from the dateString
    const date = new Date(dateString);
  
    // Get day of the week (0-6, where 0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
  
    // Get day of the month (1-31)
    const dayOfMonth = date.toLocaleDateString('en-US', { day: '2-digit' });
  
    // Get month (1-12)
    const month = date.toLocaleDateString('en-US', { month: '2-digit' });
  
    // Get year
    const year = date.getFullYear();
  
    // Construct the formatted date string
    const formattedDate = `${dayOfWeek} ${dayOfMonth}-${month}-${year}`;
  
    return formattedDate;
  };

  const all_agent_get = async (typ,id)=> {
    const data = new FormData();
   
            axios({
              method: 'post',
              url: '/api_lock/admin/all_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                    setAgentList(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const Get_type_list = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api_lock/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTypeList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const webList = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api_lock/web/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setWebsiteList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const getresponseCount = () => {
            return list.filter(item => item.manager_response == 0 ).length;
          }

          
  const getfilter_dail = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response >0)

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response >0 );

      setList(newArray)

      console.log("array ",newArray)

    }  
  }
  const getfilter_none_dial = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)

      console.log("array ",newArray)

    }  
  }

  const getInq = async ()=> {
    const data = new FormData();
    
    data.append('d1',d1);
    data.append('d2',d2);
            axios({
              method: 'post',
              url: '/api_lock/admin/date_to_date_report_ads.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;
  }

  const getfilter = status => {
    const a=list;
    setf1(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)


      console.log("array ",newArray)

    }

  
    
  }
  const getfilter_Team = status => {
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.team_id === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.team_id === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_type = status => {
    const a=list;
    setf2(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array ",newArray)
    }
  }

  const getfilter_website = status => {
    const a=list;
    setf3(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array ",newArray)
    }
    
  }

  const getfilter_Agent = (status,name) => {
    setf4(name)
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_Type_source = (status) => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.type === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getTypeCount = (array,type) => {
    return array.filter(item => item.type === type).length;
  }

  const getTypeCountSaudi = (array,type,website) => {
    return array.filter(item =>( item.type === type && item.web === website )).length;
  }



  const TeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api_lock/team/all_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeamList(s.data)
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const resetAll = () => {
    const a=listold;
    setList(a)
    setListoldStatus(false)
    setSelected()
    setSelected_website()
    setf1("")
    setf2("")
    setf3("")
    setf4("")
  }

  
  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
        <div className='card' style={{padding:"20px"}} >
<h2>Inquirers</h2>
<div className='row'>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date Start</label>
<input type="date"  value={d1} onChange={(v)=>{ setD1(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date End</label>
<input type="date"  value={d2} onChange={(v)=>{ setD2(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='row'>
<div className='col-3'>
<button onClick={()=>{

if(d1===null)
{
alert("Select Date")
}else if(d2===null)
{
alert("End Date")
}else{

  if(user=="")
  {

  }else{
    setList([])
    setSelected_website()
    setSelected()
    getInq()
    const access=JSON.parse(localStorage.getItem("Access"))
    if(access[0].a8!=true){
     window.location.replace("/admin_main")
    }
  }

}
}}  className="btn btn-primary m-r-5">Get</button>

</div>

</div>

</div>

</div>
        <h2 style={{color:"#054089"}}>Total Inquires : {list.length}</h2>
        


        
        

    <div className="card">
   
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
        <div className="row">
          <div className="col-sm-12">
            {
              section_b&&<>
               <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">
              <thead>
              <tr role="row">
                  <th
                    className="sorting_asc">
                    Date
                  </th>
                  
                  <th
                    className="sorting_asc">
                    Total Inquirers
                  </th>
                  <th
                    className="sorting_asc">
                    Fb
                  </th>
                  <th
                    className="sorting_asc">
                    Google
                  </th>
                  <th
                    className="sorting_asc">
                    Organic
                  </th>
                  <th
                    className="sorting_asc">
                    SaudiTours
                  </th>
                  <th
                    className="sorting_asc">
                    MakkahTour
                  </th>

                  <th
                    className="sorting_asc">
                    EimanTravels 
                  </th>
               
                  
                  
                 
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
              <>
                <tr role="row" className="odd">
                  <td>{formatDate(v.date)} </td>
                  
                  <td>{v.array.length} </td>
                  <td>{getTypeCount(v.array,"Fb")} </td>
                  <td>{getTypeCount(v.array,"google")} </td>
                  <td>{getTypeCount(v.array,"organic")} </td>
                  <td>{getTypeCountSaudi(v.array,"google","SaudiTours")} </td>
                  
                  <td>{getTypeCountSaudi(v.array,"google","MakkahTour")} </td>
                  <td>{ getTypeCount(v.array,"google")-getTypeCountSaudi(v.array,"google","MakkahTour")-getTypeCountSaudi(v.array,"google","SaudiTours")} </td>
                  </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
        </>
            }
           
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}