import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Padding } from '@mui/icons-material';


export default function Dashboard() {
  const [list,setList]=useState([])
  const [list_risponse,setlist_risponse]=useState([])

  const [msg,setmsg]=useState("")
  const [status,setstatus]=useState("")
  const [data,setData]=useState([])
  const [dataCall,setDataCall]=useState("")
  const [user,setUser]=useState(null);



  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);


    
        const text_th = {
          color: 'Black',
          fontSize: '14px',
         
        };
        const text_td = {
          color: 'orange',
          fontSize: '14px',
          fontWeight: 'bold',
          width: 'fit-content'
         
        };
    
    
  const getInq = async (inquiry_id,team_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);

            axios({
              method: 'post',
              url: '/api_lock/admin/inq_view_byid.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                
 const arry=s.data;
 if(arry=="[]")
 {
  console.log("null")
 }else{
  setList(s.data)
                console.log("s_data_phone",s.data)
                getphone(s.data[0].phone)
                response_list(inquiry_id)

 }


               })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const get_all_status = async (inquiry_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
  
            axios({
              method: 'post',
              url: '/api_lock/admin/view_inq_status_team_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                //setlist_risponse(s.data)

                console.log(s.data)
                console.log("get_all_status")
                setData(s.data)

               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const response_list = async (inquiry_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
  
            axios({
              method: 'post',
              url: '/api_lock/manager/view_response_id.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setlist_risponse(s.data)

                console.log(s.data)
                get_all_status(inquiry_id)
                manager_response_list(inquiry_id)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


          const getphone = async (p) => {
            try {
              const data = new FormData();
              data.append('phone', p);
          
              const response = await axios({
                method: 'post',
                url: '/api_lock/api_calls/getphone.php',
                data: data,
                headers: {'Content-Type': 'multipart/form-data'},
              });
          
              console.log("apicalls", response.data);
              setDataCall(response.data)
                 // Returning the data retrieved from the API
            } catch (error) {
              console.error('Error occurred in API call:', error);
              throw error;  // Re-throwing the error for handling at the caller's level if needed
            }
          };
            

  useEffect(() => {

    //fetchData()
    
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    if(id==null || id=='')
    {
      window.location.replace("/login")
    }

   


    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      getInq(id,JSON.parse(a)[0].team);
     
    }



  }, []);


  const [manager_list_risponse,setManagerlist_risponse]=useState([])
  const manager_response_list = async (inquiry_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
  
            axios({
              method: 'post',
              url: '/api_lock/manager/manager_view_response_id.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setManagerlist_risponse(s.data)

                console.log(" setManagerlist_risponse ",s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };





  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
            <div className='row'>
        
          
            <div className='col-5'>
            <div className="card">
  
  
   <div className="card-body">
 <div>
      <div >
      
        <div className="row">
          <div >
          
      {
        list.map((v)=>(   
        

            <div >
                
            <h1>Received Inquiry</h1>
            
            
         <div className='row'>
            <div className='col-6' style={text_th} >Inquiry ID</div>
            <div className='col-6' style={text_td}>{v.id}</div>
          
            </div>
         <div className='row'>
       
            <div className='col-6' style={text_th} >Web</div>
            <div className='col-6' style={text_td}>{v.web}</div>
            </div>

         <div className='row'>
            <div className='col-6' style={text_th} >Inquiry Date</div>
            <div className='col-6' style={text_td}>{v.date_portal} {v.date_time}</div>
          
            </div>
         <div className='row'>
       
            <div className='col-6' style={text_th} >Inquiry Received</div>
            <div className='col-6' style={text_td}>{v.sender}</div>
            </div>

         <div className='row'>
            <div className='col-6' style={text_th} >Name</div>
            <div className='col-6' style={text_td}>{v.name}</div>

            </div>
         <div className='row'>
   
            <div className='col-6' style={text_th} >Email</div>
            <div className='col-6' style={text_td}>{v.email}</div>
            </div>

         <div className='row'>
            <div className='col-6' style={text_th} >Phone </div>
            <div className='col-6' style={text_td}>{v.phone}</div>
          
            <div className='col-6' style={text_th} >Departure</div>
            <div className='col-6' style={text_td}>{v.dep}</div>
            </div>
         <div className='row'>
            <div className='col-6' style={text_th} >Arrival</div>
            <div className='col-6' style={text_td}> {v.arri}</div>
            <div className='col-6' style={text_th} >Tour Date</div>
            <div className='col-6' style={text_td}>{v.date}</div>
            </div>
         <div className='row'>
            <div className='col-6' style={text_th} >Makkah</div>
            <div className='col-6' style={text_td}>{v.makkah}</div>
            <div className='col-6' style={text_th} >Madina</div>
            <div className='col-6' style={text_td}>{v.madina}</div>
            </div>
         <div className='row'>
            <div className='col-6' style={text_th} >Rooms</div>
            <div className='col-6' style={text_td}>{v.rooms}</div>
            <div className='col-6' style={text_th} >Accommodation</div>
            <div className='col-6' style={text_td}>{v.acc}</div>
            <div className='col-6' style={text_th} >Type</div>
            <div className='col-6' style={text_td}>{v.type}</div>
            <div className='col-6' style={text_th} >Url</div>
            <div className='col-6' style={text_td}><a href={v.url}>Click</a> </div>
            </div>

         <div className='row'>
           
         {/* view_response_id.php */}
            {
                v.duration==""?<></>:<> <div className='col-6' style={text_th} >Duration</div>
                <div className='col-3' style={text_td}>{v.duration}</div></>
            }
           
            </div>

            <div >
     
     <div className="row">
       <div >
       
   {
     data.map((v)=>(   
     

         <div >
             
         <h3>Details</h3>
         
         {
          data[0].msg=="agent"?<>
          <div className='row'>
         <div className='col-6' style={text_th} >Team name k</div>
         <div className='col-6' style={text_td}>{v.tname}</div>
         </div>
          <div className='row'>
         <div className='col-6' style={text_th} >Agent Name</div>
         <div className='col-6' style={text_td}>{v.uname}</div>
         </div>
         <br/>

         
          
         </>:<></>
         }

    
  

    
       </div>
    
        
        

   ))
   }
     
       </div>
     </div>
     
   </div>
          </div>
       
           
           

      ))
      }
        
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


      
        
        
      </div>
      
    
    </div>
            </div>
            <div className='col-7'>
            <div className="card">
  
  
  <div className="card-body">
<div>
     <div >
     
       <div className="row">
         <div >
         
     {
       data.map((v)=>(   
       

           <div >
               
           <h3>Call History</h3>
           
           {
  dataCall==""?<h2 style={{color:'red'}}>loading</h2>:dataCall.status!="success"?<>Failed </>:<>
  {
dataCall.result.map((v,i)=>(
  <div className='card'>
  <div className='row' style={{margin:'2px'}}>
    <table>
      <tr>
        <td>DID</td>
        <td style={{color:'red'}}>{v.DID}</td>
       
        <td>Date</td>
        <td style={{color:'red'}}>{v.Date} </td>
        <td>Time</td>
        <td style={{color:'red'}}>{v.Time}</td>
        
    
      </tr>
      <tr>
        
       
      <td>Title</td>
        <td style={{color:'red'}}>{v.Title}</td>

        <td>Duration</td>
        <td style={{color:'red'}}>{v.Duration}</td>
       
        {/* <td>RingTime</td>
        <td style={{color:'red'}}>{v.RingTime}  </td> */}
        <td>Type</td>
        <td> <span style={{color:'blue'}}> {v.Type}</span> </td>
        
    
      </tr>
    </table>

              
                </div>
                </div>
))          
  }
  </>
  

}
  
      
    

      
         </div>
      
          
          

     ))
     }
       
         </div>
       </div>
       
     </div>
   </div>
 
 </div>   
   </div>

            </div>
        </div>

  

<div>
{
  manager_list_risponse.length>0?<>
  <div class="table-responsive">
    <h2>History Manager</h2>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Msg</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Type</th>
            </tr>
        </thead>
        <tbody>
            {
              manager_list_risponse.map((v,ii)=>(
                <tr>
                <th scope="row">{v.i}</th>
                <td>{v.s=="Reminder"?<> {v.s} ({v.date}) </>:v.s}</td>
                <td>{v.msg}</td>
                <td>{v.date} {v.at}</td>
                <td>{v.type=="true"?"Incorrect":"correct"}</td>
                
            </tr>
              ))
            }
            
        </tbody>
    </table>
</div>
</>:<></>
}  
  
    <div class="table-responsive">
    
    <h2>Agent History</h2>
    <table class="table">
    
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Msg</th>
                <th scope="col">At</th>
            </tr>
        </thead>
        <tbody>
            {
              list_risponse.map((v,ii)=>(
                <tr>
                <th scope="row">{v.i}</th>
                <td>{v.s=="Reminder"?<> {v.s} ({v.date}) </>:v.s}</td>
                <td>{v.msg}</td>
                <td>{v.at}</td>
            </tr>
              ))
            }
            
        </tbody>
    </table>
</div>



</div>



        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}