import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('id',id);
            axios({
              method: 'post',
              url: '/api_lock/agent/get_waiting.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                console.log(id)
                getstate(id);
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getstate = async (team_id)=> {
    const data = new FormData();
        data.append("team_id",team_id)
            axios({
              method: 'post',
              url: '/api_lock/manager/state.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setstate(s.data)
                console.log(s.data)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

 




  const View_time_update = async (inquiry_id,agent_id)=> {

    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('agent_id',agent_id);
        
            axios({
              method: 'post',
              url: '/api_lock/agent/view_time_update.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                console.log(s.data)
                getInq(JSON.parse(user)[0].id)

             
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const [list,setList]=useState([])
  const [state,setstate]=useState([])
  const [Reminder,setReminder]=useState([])

 
  const [selected,setSelected]=useState(null)

  const [user,setUser]=useState(null);

  useEffect(() => {
    const a=localStorage.getItem("Agent");
    if(a==null)
    {
     // window.location.replace("/login")
    }else{
      setUser(a)
      getInq(JSON.parse(a)[0].id);
      getInq_Reminder(JSON.parse(a)[0].id)
    }


  
  
  }, []);
  

  function getRemainingDays(targetDate) {
    const currentDate = new Date();
    const remainingTime = new Date(targetDate) - currentDate;
    
    // Convert remaining time from milliseconds to days
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
    
    return remainingDays;
}

const getInq_Reminder = async (id)=> {
  const data = new FormData();
  data.append('agent_id',id);
  
          axios({
            method: 'post',
            url: '/api_lock/agent/reminder.php',
            data: data,
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then(s => {
              
              const se=s.data;
              se.sort((a, b) => {
                return new Date(a.date_reminder) - new Date(b.date_reminder);
            });
              setReminder(se)
            
              
       
            })
            .catch(s => {
              console.log('here catch =>>', s);
            });
        };


  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            
          <div className="col-md-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Response Pending </p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          <div className="col-md-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Reminder List </p>
                      <h2 className="m-b-0">
                      <span>{Reminder.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>


    

  

           



          </div>
       
          {/* <div className="card" style={{backgroundColor :"#696969"}}>
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4 style={{color :"white"}}>Reminder Alert  </h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                 
                  <th className="sorting">Date</th>
                  <th className="sorting">Remaing Date</th>
                  <th className="sorting">Type</th>
                  <th
                    className="sorting">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        Reminder.map((v)=>(   
        

              <>
{
              getRemainingDays(v.date_reminder)==0?
              
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time}</td>
                  <td>{v.sender_time}</td>
                  <td>{v.agent_status}</td>
                  <td>{v.date_reminder}</td>
                  <td>
                    {  getRemainingDays(v.date_reminder)==0?"Today":getRemainingDays(v.date_reminder)<0?<div style={{color:'red'}}>Late {getRemainingDays(v.date_reminder)} Days</div>:<div style={{color:"green"}}>{getRemainingDays(v.date_reminder)}</div>}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <>{v.url}</>
      
        }
      >
        <Button>{v.sender_type}</Button>
      </HtmlTooltip>
      </td>
      
     <td>
      <a target='black_' href={"/view_inquiry?id="+v.id}>
        <button 
        className="btn btn-warning m-r-5">View</button>
                 
                 </a>
                  </td>
                </tr>:""
              }
              
                
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    
    </div>
           */}
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Received Inquiry <span onClick={()=>{getInq(JSON.parse(user)[0].id);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Makkah / Madina
                  </th>
                  <th className="sorting"> Ad/ch/In</th>
                  <th className="sorting">Account</th>
                  <th
                    className="sorting">
                    Action
                  </th>
                </tr>
              </thead>0
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time}</td>
                  <td>{v.rec_time}</td>
                  <td>{v.makkah} / {v.madina}</td>
                  <td>{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <>{v.url}</>
      
        }
      >
        <Button>{v.type_}</Button>
      </HtmlTooltip>
      </td>
      
     <td>
      <a target='black_' href={"/view_inquiry?id="+v.id}>
        <button onClick={()=>{
            
           


                     View_time_update(v.id,JSON.parse(user)[0].id)

                     
                    
                    
                  }} className="btn btn-warning m-r-5">View</button>
                 
                 </a>
                  </td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}