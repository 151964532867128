import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('team_id',id);
            axios({
              method: 'post',
              url: '/api_lock/contact/view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                setList(s.data)
                console.log(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getstate = async (team_id)=> {
    const data = new FormData();
        data.append("team_id",team_id)
            axios({
              method: 'post',
              url: '/api_lock/manager/state.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setstate(s.data)
                console.log(s.data)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const send_query = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    data.append('s',"trash");
        
            axios({
              method: 'post',
              url: '/api_lock/inq/move.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const access_checker=()=>{
            const b=JSON.parse(localStorage.getItem("Access"))
            if(b[0].a2==false)
            {
              window.location.replace("/view_pending_admin")
            }
          }




//   const send_team_to_agent = async (inquiry_id,manager_id,agent_id)=> {

//     const data = new FormData();
//     data.append('inquiry_id',inquiry_id);
//     data.append('sender_id',manager_id);
//     data.append('agent_id',agent_id);
        
//             axios({
//               method: 'post',
//               url: '/api_lock/manager/inq_send.php',
//               data: data,
//               headers: {'Content-Type': 'multipart/form-data'},
//             })
//               .then(s => { 
//                 console.log(s.data)
//                 getInq(JSON.parse(user)[0].team)
             
//               })
//               .catch(s => {
//                 console.log('here catch =>>', s);
//               });
//           };

  const [list,setList]=useState([])
  const [state,setstate]=useState([])

 
  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [user,setUser]=useState(null);



  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      access_checker()
      setUser(a)

      getInq();

     const access=JSON.parse(localStorage.getItem("Access"))
     if(access[0].a17!=true)
     {
      window.location.replace("/admin_main")
     }

     
    }


  
  
  }, []);

  const return_team = async (id)=> { 
    const data = new FormData();
        data.append('id',id);
       
            axios({
              method: 'post',
              url: '/api_lock/contact/delete.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                getInq(JSON.parse(user)[0].team);
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };






  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Pending </p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          
           
          </div>
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Contact US<span onClick={()=>{getInq(JSON.parse(user)[0].team);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                 
                  
                
             
                 
                  <th className="sorting">
                   Phone
                  </th>
                  <th className="sorting">
                   Action
                  </th>
                  <th
                    className="sorting">
                    Massage
                  </th>

                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  
                
                 
                  
                  <td>{v.phone}</td>
           

      <td>
        <button onClick={()=>{
                 
                   return_team(v.id)
                  }} className="btn btn-icon btn-warning">
    <i className="anticon anticon-close"></i> 
</button>
<br/>
<br/><a href={"/contact_to_complaint?id="+v.id}>
        <button  className="btn btn-icon btn-warning">
   <i class="anticon anticon-select"></i>
</button>
</a>


 </td>
<td><h6 style={{color:"green"}}>{v.web}</h6>  {v.msg}</td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}