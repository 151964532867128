import React, { useState, useRef, useEffect } from 'react';

const ImageInput = () => {
  const [imageUrl, setImageUrl] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    const handlePaste = (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = (e) => {
            setImageUrl(e.target.result);
          };
          reader.readAsDataURL(blob);
        }
      }
    };

    const divElement = inputRef.current;
    divElement.addEventListener('paste', handlePaste);

      return () => {
      divElement.removeEventListener('paste', handlePaste);
    };
  }, []); // Empty dependency array ensures that this effect runs only once during component mounting

  const handleInputChange=(event) => {
    setImageUrl(event.target.value);
  };

  return (
  <div>
  <div contentEditable style={{ border: '1px solid black', padding: '5px', minHeight: '100px',minWidth:"500px" }} ref={inputRef}></div>
  <br/>
 {
  imageUrl
 }
  </div>
  );
};

export default ImageInput;