import * as React from 'react';
import { useState, useEffect } from "react";
import Papa from 'papaparse';
import axios from 'axios';
import Nav from "./nav";
import Footer from "./footer";




export default function Dashboard() {

  const [csvData, setCsvData] = useState([]);


  // const handleFileInputChange = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     const csvString = reader.result;
  //     Papa.parse(csvString, {
  //       complete: (result) => {
  //         console.log('Parsed CSV result:', result);
  //         setCsvData(result.data);
  //       },
  //       header: true, // If your CSV has headers, set this to true
  //       dynamicTyping: true
  //     });
  //   };

  //   reader.readAsText(file);
  // };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    
    Papa.parse(file, {
      complete: (result) => {
        console.log('Parsed CSV result:', result);
        setCsvData(result.data);
      },
      header: false // Assuming there's no header in the CSV file
    });
  };

 
 


const upload =()=> {


  //  web,name,email,no,url,type,row
    csvData.map((row,index)=>{
      
     if(index>0)
     {

      if(row[0]=="" || row[3]=="")
      {
        
      }else{
        add(row[0],row[1],row[2],row[3],row[4],row[5],index)
      }
      
     }


})

  
  
};

  // Function to update the value within the multidimensional array
  const updateValue = (rowIndex, columnIndex, newValue) => {
    // Make a copy of the multidimensional array
    const updatedData = [...csvData];
    
    // Update the value at the specified row and column indices
    if (updatedData[rowIndex] && updatedData[rowIndex][columnIndex]) {
      updatedData[rowIndex][columnIndex] = newValue;
      // Update the state with the updated multidimensional array
      setCsvData(updatedData);
    }
  };



const add = async (web,name,email,no,url,type,row)=> {

const data = new FormData();
    
    data.append('website',web);
    data.append('name',name);
    data.append('email',email);
    data.append('phone',no);
    data.append('url',url);
    data.append('type',type);

        axios({
          method: 'post',
          url: '/api_lock/inq/fb.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            if(s.data[0].msg=="pass")
            {
              updateValue(row,6,"Uploaded");

             }
     
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      
      useEffect(() => {
        const access=JSON.parse(localStorage.getItem("Access"))
        if(access[0].a16!=true){
          window.location.replace("/admin_main")
          
        }
      }, []);


  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
<div className='card' style={{padding:"20px"}} >
  <h2 style={{padding:"1px"}}>All Type CSV File</h2>

<div className='row'>
    <div className='col-4'>
    <div class="form-group">
        <label for="formGroupExampleInput">CVS File</label>
        <input type="file" onChange={handleFileInputChange} class="form-control" id="formGroupExampleInput" placeholder="select file"/>
    </div>
    </div>


</div>


{csvData.length > 0 && (<>
  <div className='row'>
    <div className='col-4'>
    <button onClick={upload}  className="btn btn-warning m-r-5"> Upload </button>
    </div>
  </div>
  <br/>


</>)}

<div>

</div>
<div className="table-responsive">
        {csvData.length > 0 && (
          <table className="table">
            <tbody>
              {csvData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <>
                    <td key={cellIndex}>{cell =="Uploaded"?<><i style={{color:"green",fontSize:"23px"}}  className="anticon anticon-check-circle"></i></>:cell=="pending"?<><i className="fas fa-cloud-upload-alt"></i></>:cell}   </td>
                    
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
            {/* <thead>
          <tr>
            {csvData.length > 0 &&
              Object.keys(csvData[0]).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {csvData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody> */}
          </table>
        )}
      </div>



</div>

{/* {
  csvData[0][1]+
  csvData[0][2]
}

{
  csvData.length
} */}



          
        </div>
   
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
  </>
  );
}
