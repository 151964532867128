import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';


export default function Dashboard() {
  
    
    const [user,setUser]=useState(null);
    const [userAccess,setUserAccess]=useState([{"a1":false,"a2":false,"a3":false,"a4":false,"a5":false,"a6":false,"a7":false,"a8":false,"a9":false,"a10":false,"a11":false,"a12":false,"a13":false,"a14":false,"a15":false,"a16":false,"a17":false,"a18":false,"a19":false,"a20":false,"a21":false,"a22":false,"a23":false,"a24":false,"a25":false,"a26":false,"a27":false,"a28":false,"a29":false,"a30":false,"a31":false,"a32":false,"a33":false}]);

  useEffect(() => {
    setUserAccess(JSON.parse(localStorage.getItem("Access")))
    
    const a=localStorage.getItem("Admin");
    
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      const pin=localStorage.getItem("PIN")
      if(pin=="")
      {
        window.location.replace("/pin")
      }
      
     
  
    }

  }, []);

  const add_pin = async (uid)=> {

    const data = new FormData();

    data.append('uid',uid );
    data.append('s', "Out");
   
        axios({
          method: 'post',
          url: '/api_lock/user/pin_add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
             localStorage.setItem("PIN","") 
             window.location.replace("/pin")
            
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };

 

  return (
  <>
  
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
  <title>Admin Dashboard </title>
  <div className="app">
    <div className="layout">
      {/* Header START */}
      <div className="header">
        <div className="logo logo-dark">
          <a href="/">
            <img src="assets/images/logo/logo.png" alt="Logo" />
            <img
              className="logo-fold"
              src="assets/images/logo/logo-fold.png"
              alt="Logo"
            />
          </a>
        </div>
        <div className="logo logo-white">
          <a href="/">
            <img src="assets/images/logo/logo-white.png" alt="Logo" />
            <img
              className="logo-fold"
              src="assets/images/logo/logo-fold-white.png"
              alt="Logo"
            />
          </a>
        
        </div>
      
   
        
        <div className="nav-wrap">

          <ul className="nav-left">
            <li className="desktop-toggle">
              <a href="javascript:void(0);">
                <i className="anticon" />
              </a>
            </li>
            <li className="mobile-toggle">
              <a href="javascript:void(0);">
                <i className="anticon" />
              </a>
            </li>
            <li>
          
            </li>
            <li>{user==null?<></>:JSON.parse(user)[0].name}</li>
          </ul>
          <ul className="nav-right">
            <li className="dropdown dropdown-animated scale-left">
              <div className="pointer" data-toggle="dropdown">
                <div className="avatar avatar-image  m-h-10 m-r-15">
                <i style={{color:"#202020"}} className="anticon anticon-arrow-down"></i>
                </div>
              </div>
              <div className="p-b-15 p-t-20 dropdown-menu pop-profile">
                <div className="p-h-20 p-b-15 m-b-10 border-bottom">
                  <div className="d-flex m-r-50">
                    <div className="avatar avatar-lg avatar-image">
                    <i style={{color:"#202020"}} className="anticon anticon-arrow-down"></i>
                    </div>
                    <div className="m-l-10">
                      <p className="m-b-0 text-dark font-weight-semibold">
                      {user==null?<></>:JSON.parse(user)[0].name}
                      </p>
                      <p className="m-b-0 opacity-07"> {user==null?<></>:JSON.parse(user)[0].name}</p>
                    </div>
                  </div>
                </div>
                
              
                
                <p
                   onClick={()=>{
                    localStorage.clear()
                    window.location.replace("/login")
                    
                    }}
                  className="dropdown-item d-block p-h-15 p-v-10">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">Logout</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>

                {
                  localStorage.getItem("DEV")=="true"?<>
                  <p className="dropdown-item d-block p-h-15 p-v-10">
                  <div onClick={()=>{
                    window.location.replace("/dev")
                  }} className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">////</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>
                  </>:
                  <></>

                }
                
              </div>
            </li>
            <li>
              <a>
               <i   onClick={()=>{
                  add_pin(JSON.parse(user)[0].id)
                }}
                 className="anticon anticon-logout"></i>
              </a>
            </li>
          
          </ul>
        </div>
      </div>
      {/* Header END */}
      {/* Side Nav START */}
     

<div className="side-nav">
  <div className="side-nav-inner">
    <ul className="side-nav-menu scrollable ps-container ps-theme-default" data-ps-id="e978f676-4d08-b2bd-3b7b-66f20b752c4e">
      {
        userAccess[0].a1&&<>
        <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/admin_main">
          <span className="icon-holder">
            <i className="anticon anticon-dashboard" />
          </span>
          <span className="title" >Dashboard </span>
        </a>
      </li>
        {/* <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/admin_main_types">
          <span className="icon-holder">
            <i className="anticon anticon-dashboard" />
          </span>
          <span className="title">Dashboard Types </span>
        </a>
      </li> */}
      </>      }

      {
        userAccess[0].a2&&<><li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/team_to_agent_pending">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Inquiry Sent Pending</span>
        </a>
      </li></>
      }
      
      {
        userAccess[0].a3&&<><li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/view_pending_admin">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Inquiry View Pending</span>
        </a>
      </li></>
      }

      {
        userAccess[0].a4&&<>  <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/waiting_response_admin">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Response Waiting</span>
        </a>
      </li>
      </>
      }
    
      {
        userAccess[0].a5&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/follow_up_admin">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Follow up</span>
        </a>
      </li></>
      }



      {
        userAccess[0].a6&&<>      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/reminder_list">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Reminder List</span>
        </a>
      </li>
      </>
      }



      {
        userAccess[0].a7&&<>      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/voicemail">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Voicemail</span>
        </a>
      </li></>
      }



      {
        userAccess[0].a8&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/inq_date_to_date">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Report Catlog</span>
        </a>
      </li></>
      }


      {
        userAccess[0].a10&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/dup">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Duplicate Inquiry</span>
        </a>
      </li>
      </>
      }

      {
        userAccess[0].a17&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/client_discount">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Client Subscriptions</span>
        </a>
      </li>
      </>
      }
      {
        userAccess[0].a18&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/contact_us_all">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Contact US</span>
        </a>
      </li>
      </>
      }
      {
        userAccess[0].a18&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/newcomplaint">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">New Complaint</span>
        </a>
      </li>
      </>
      }
      {
        userAccess[0].a18&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/complaintlist">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Complaint List</span>
        </a>
      </li>
      </>
      }
   
      {
        userAccess[0].a18&&<>
              <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/convert_complaint_list">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Convert Complaint List</span>
        </a>
      </li>
      </>
      }
       

   

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} className="dropdown-toggle" href="javascript:void(0);">
          <span className="icon-holder">
            <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Users</span>
          <span className="arrow">
            <i className="arrow-icon" />
          </span>
        </a>
        <ul className="dropdown-menu" style={{ display: "none" }}>
        {
        userAccess[0].a11&&<>
          <li>
            <a style={{textDecoration:"none"}} href="/users">All Users</a>
          </li>
         
          </>
      }

        {
        userAccess[0].a12&&<>
         
          <li>
            <a style={{textDecoration:"none"}} href="/newusers">New User</a>
          </li>
          </>
      }
        {
        userAccess[0].a13&&<>
         
          <li>
          <a style={{textDecoration:"none"}} href="/team">Team</a>
          </li>
          </>
      }
        {
        userAccess[0].a14&&<>
         
         <li>
            <a style={{textDecoration:"none"}} href="/userinfo">Statis</a>
          </li>
        
          <li>
            <a style={{textDecoration:"none"}} href="/log">Portal Log</a>
          </li>
          <li>
            <a style={{textDecoration:"none"}} href="/pinview">Pin Log</a>
          </li>
          </>
      }
        
      
        </ul>
      </li>

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} className="dropdown-toggle" href="javascript:void(0);">
          <span className="icon-holder">
            <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Others</span>
          <span className="arrow">
            <i className="arrow-icon" />
          </span>
        </a>
        <ul className="dropdown-menu" style={{ display: "none" }}>
         
        {
        userAccess[0].a15&&<>
        <li>
            <a style={{textDecoration:"none"}} href="/agent_report_list">Export Report</a>
          </li></>
      }

        {
        userAccess[0].a16&&<>
         <li>
            <a style={{textDecoration:"none"}} href="/files">Import Inquirers</a>
          </li>
          </>
        }

        {
        userAccess[0].a16&&<>
         <li>
            <a style={{textDecoration:"none"}} href="/report_ads">Report Inquirers Ads</a>
          </li>
          </>
        }
          
         
          <li>
            <a style={{textDecoration:"none"}} href="/web">Webs & Type</a>
          </li>
          
          
        </ul>
      </li>

      
    </ul>
  </div>
</div>

      {/* Side Nav END */}
    
      
      
    </div>
  </div>
</>

  
  );
}