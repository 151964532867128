import * as React from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

export default function SignInSide() {

  const [username,setUsername]=React.useState("")
  const [password,setPassword]=React.useState("") 
  const [msg,setMsg]=React.useState("")
  const [user,setUser]=React.useState([]);

  React.useEffect(() => {
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      
    }else{
     
      setUser(JSON.parse(a))
      
    }

    const b=localStorage.getItem("Admin");
    if(b==null)
    {
      
    }else{

      setUser(JSON.parse(b))
       
    }

    const c=localStorage.getItem("Agent");
    if(c==null)
    {
      
    }else{
    
      setUser(JSON.parse(c))
    
    }
    
  }, []);

const pin=()=>{
  if(password==user[0].pin)
  {
    console.log("true")
    add_pin(user[0].id,"In")
    
  }else{
    console.log("false")
    setMsg("PIN Worng and Try again !")
  }
}



  //add data
  const add_pin = async (uid,status)=> {
    console.log("aaaaa")
    const data = new FormData();
   
    data.append('uid', uid);
    data.append('s', status);
   
        axios({
          method: 'post',
          url: '/api_lock/user/pin_add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
             localStorage.setItem("PIN","true")
             window.location.replace("/login")
            
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };



  return (
    <>
    <meta charSet="utf-8" />
    <meta  name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content="best developer" />
  <meta name="keywords" content="Portal" />
  <meta name="author" content="Umar Chughtai" />
    <title>IT Ninjas PIN</title>

    <div className="app" >
      <div
        className="container-fluid p-h-0 p-v-20 bg full-height d-flex"
        style={{ backgroundImage: 'url("assets/images/others/login-3.png")' }}
      >
        <div className="d-flex flex-column justify-content-between w-100">
        
          <div className="container d-flex h-100">
            <div className="row align-items-center w-100">
              <div className="col-md-7 col-lg-5 m-h-auto">
                <div className="card shadow-lg">
                  <div className="card-body">
                   
                    <div className="d-flex align-items-center justify-content-between m-b-30">
                     
                    <center><h2 className="m-b-0">2F Locker</h2></center>
                      <img  className="img-fluid"  alt="logo"  src="assets/images/logo/logo.png" />
                    </div>
                    
                    
                      
                      <div className="form-group">
                        <label
                          className="font-weight-semibold"
                          htmlFor="password">
                          PIN: 
                        </label>
                      
                        <div className="input-affix m-b-10">
                          <i className="prefix-icon anticon anticon-lock" />
                          <input
                            type="password"
                            value={password}
                            className="form-control"
                            id="password"
                            placeholder="PIN"
                            onChange={(v)=>{

                                var a=v.target.value;
                                if(a.length<5)
                                {
                                    setPassword(v.target.value)
                                    setMsg("")
                                }else{
                                  setMsg("Only 4 Digit PIN")
                                }

                                
                            }}
                          />
                        </div>
                        <div className="input-affix m-b-10">
                          {
                            msg==""?"":<Alert severity="warning">{msg}</Alert>
                          }
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          
                          <button onClick={pin} className="btn btn-primary">Submit</button>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/logo/it.png" width={"400px"} />
        </div>
      </div>
    </div>
  </>
  
  );
}