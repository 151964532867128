import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  const getInq = async (id)=> {
    const data = new FormData();
    data.append('team_id',id);
            axios({
              method: 'post',
              url: '/api_lock/admin/view_waiting.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
               
               
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const [list,setList]=useState([])
  const [user,setUser]=useState(null);

  


  const return_agent = async (id)=> {
    const data = new FormData();
        data.append('id',id);
       
            axios({
              method: 'post',
              url: '/api_lock/admin/agent_return.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                getInq(JSON.parse(user)[0].team);
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      
      setUser(a)
      getInq(JSON.parse(a)[0].team);
      const access=JSON.parse(localStorage.getItem("Access"))
      if(access[0].a3!=true)
      {
       window.location.replace("/admin_main")
      }

    }
  
  }, []);








  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Pending </p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          
           
          </div>
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>View Pending <span onClick={()=>{getInq(JSON.parse(user)[0].team);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th className="sorting_asc">
                    ID
                  </th>
                  <th className="sorting_asc">
                    Website
                  </th>
                  <th  className="sorting">
                    Name
                  </th>
                  <th  className="sorting">
                    Email
                  </th>
                  <th className="sorting">
                    Phone
                  </th>
                  
                  <th className="sorting">
                    Sending info
                  </th>
                 
                  <th
                    className="sorting">
                        Agent Name
                  </th>
                  <th className="sorting">
                  Action
                  </th>
                  <th className="sorting">
                  View
                  </th>

                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                  <td>{v.phone}</td>
                  
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>

      
           
           <div className='row'>
            <div className='col-3'>IP</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.ip}</b></div>
           </div>

           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Ad/ch/In</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} >{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>


           <div className='row'>
            
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
        <Button>{v.rec_time}</Button>
      </HtmlTooltip>
      </td>
     
      <td>
        {v.agent_name}
      </td>
      <td><button onClick={()=>{
                 
                 return_agent(v.id)
                }} className="btn btn-icon btn-warning">
  <i className="anticon anticon-close"></i>
</button> </td>
<td>
<a href={"view_inquiry_admin?id="+v.id}> <button  className="btn btn-warning m-r-5">Info</button></a>
 </td>   
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}