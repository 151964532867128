import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav";
import Footer from "./footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
  const getInq = async (type,status)=> {
    const data = new FormData();
    data.append('type',type);
    data.append('status',status);

            axios({
              method: 'post',
              url: '/api_lock/complaint/complaint_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                setList(s.data)
                

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const access_checker=()=>{
            const b=JSON.parse(localStorage.getItem("Access"))
            if(b[0].a2==false)
            {
              window.location.replace("/view_pending_admin")
            }
          }




//   const send_team_to_agent = async (inquiry_id,manager_id,agent_id)=> {

//     const data = new FormData();
//     data.append('inquiry_id',inquiry_id);
//     data.append('sender_id',manager_id);
//     data.append('agent_id',agent_id);
        
//             axios({
//               method: 'post',
//               url: '/api_lock/manager/inq_send.php',
//               data: data,
//               headers: {'Content-Type': 'multipart/form-data'},
//             })
//               .then(s => { 
//                 console.log(s.data)
//                 getInq(JSON.parse(user)[0].team)
             
//               })
//               .catch(s => {
//                 console.log('here catch =>>', s);
//               });
//           };

  const [list,setList]=useState([])
  const [state,setstate]=useState([])

 
  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [user,setUser]=useState(null);



  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      access_checker()
      setUser(a)

      getInq("Complaint","open");

     const access=JSON.parse(localStorage.getItem("Access"))
     if(access[0].a17!=true)
     {
      window.location.replace("/admin_main")
     }

     
    }


  
  
  }, []);

  const return_team = async (id)=> { 
    const data = new FormData();
        data.append('id',id);
       
            axios({
              method: 'post',
              url: '/api_lock/contact/delete.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                getInq(JSON.parse(user)[0].team);
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };






  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Pending Complaint's</p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          
           
          </div>
          
        


    <div >
      
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>All Complaints<span onClick={()=>{getInq(JSON.parse(user)[0].team);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   </div>
   <div>
 <div>
 


      <div id="data-table_wrapper" className="dataTables_wrapper ">

        {
          list.map((v)=>(
            <div className='card' >
             
            <div className="row" style={{margin:"10px"}}>
            
              <div className="col-1">
              <a href={'/show_complaint?id='+v.id}>
              <button  className="btn btn-icon btn-warning">
              <i className="anticon anticon-select"></i> 
              </button>
              </a>
              </div>
            

              <div className="col-1">
                <h6>Type</h6>
                <p>{v.type}</p>
                </div>

              <div className="col-1">
                <h6>Folder</h6>
                <p>{v.folder}</p>
                </div>

              <div className="col-2">
                <h6>Option</h6>
                <p>{v.option}</p>
                </div>


            
             
              <div className="col-2">
                <h6>Agent</h6>
                <p>{v.agent_name}</p>
                </div>

              <div className="col-3">
                <h6>Create At</h6>
                <p>{v.create_at}</p>
                </div>
              <div className="col-12">
               
                <h3>{v.heading}</h3>
                <p>
                 {
                  v.remarks
                 } 
                </p>
                </div>
                
                </div>
            
            </div>
          ))
        }
      
        
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    </div>
        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}